import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'

/**
 * Base
 */
// Debug
const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl') as HTMLCanvasElement;

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

const doorColorTexture = textureLoader.load('/textures/door/color.jpg')
const doorAlphaTexture = textureLoader.load('/textures/door/alpha.jpg')
const doorAmbientOcclusionTexture = textureLoader.load('/textures/door/ambientOcclusion.jpg')
const doorHeightTexture = textureLoader.load('/textures/door/height.jpg')
const doorNormalTexture = textureLoader.load('/textures/door/normal.jpg')
const doorMetalnessTexture = textureLoader.load('/textures/door/metalness.jpg')
const doorRoughnessTexture = textureLoader.load('/textures/door/roughness.jpg')

const bricksColorTexture = textureLoader.load('/textures/bricks/color.jpg')
const bricksAmbientOcclusionTexture = textureLoader.load('/textures/bricks/ambientOcclusion.jpg')
const bricksNormalTexture = textureLoader.load('/textures/bricks/normal.jpg')
const bricksRoughnessTexture = textureLoader.load('/textures/bricks/roughness.jpg')

const grassColorTexture = textureLoader.load('/textures/grass/color.jpg')
const grassAmbientOcclusionTexture = textureLoader.load('/textures/grass/ambientOcclusion.jpg')
const grassNormalTexture = textureLoader.load('/textures/grass/normal.jpg')
const grassRoughnessTexture = textureLoader.load('/textures/grass/roughness.jpg')

grassColorTexture.repeat.set(12, 12);
grassAmbientOcclusionTexture.repeat.set(12, 12);
grassNormalTexture.repeat.set(12, 12);
grassRoughnessTexture.repeat.set(12, 12);

grassColorTexture.wrapS = THREE.RepeatWrapping
grassAmbientOcclusionTexture.wrapS = THREE.RepeatWrapping
grassNormalTexture.wrapS = THREE.RepeatWrapping
grassRoughnessTexture.wrapS = THREE.RepeatWrapping

grassColorTexture.wrapT = THREE.RepeatWrapping
grassAmbientOcclusionTexture.wrapT = THREE.RepeatWrapping
grassNormalTexture.wrapT = THREE.RepeatWrapping
grassRoughnessTexture.wrapT = THREE.RepeatWrapping
/**
 * House
 */

const house = new THREE.Group();
scene.add(house);
const houseSettings = {
    width: 4,
    height: 2.5,
    wallsColor: '#ac8e82',
    roofColor: "#be4242",
    roofHeight: 1.5,
    doorHeight: 2.2
}

// walls
const walls = new THREE.Mesh(
    new THREE.BoxGeometry(houseSettings.width, houseSettings.height, houseSettings.width),
    new THREE.MeshStandardMaterial({
        map: bricksColorTexture,
        roughnessMap: bricksRoughnessTexture,
        aoMap: bricksAmbientOcclusionTexture,
        normalMap: bricksNormalTexture,
        aoMapIntensity: 0.1,
    })
)
walls.position.y = houseSettings.height / 2;

// roof
const roof = new THREE.Mesh(
    new THREE.ConeGeometry( houseSettings.width * 0.9, houseSettings.roofHeight, 4 ),
    new THREE.MeshStandardMaterial({color: houseSettings.roofColor})
)
roof.position.y = houseSettings.height + (houseSettings.roofHeight / 2)
roof.rotation.y = Math.PI * 0.25;


// door
const door = new THREE.Mesh(
    new THREE.PlaneGeometry(houseSettings.doorHeight, houseSettings.doorHeight, 100, 100),
    new THREE.MeshStandardMaterial({ 
        map: doorColorTexture,
        transparent: true,
        alphaMap: doorAlphaTexture,
        aoMap: doorAmbientOcclusionTexture,
        displacementMap: doorHeightTexture,
        displacementScale: 0.1,
        normalMap: doorNormalTexture,
        metalnessMap: doorMetalnessTexture,
        roughnessMap: doorRoughnessTexture
    })
)
//door.rotation.x = - Math.PI * 0.5
door.position.z = (houseSettings.width / 2) + 0.01;
door.position.y = houseSettings.doorHeight / 2;
scene.add(door)


house.add(walls, roof, door);



// Bushes
const bushGeometry = new THREE.SphereGeometry(1, 16, 16)
const bushMaterial = new THREE.MeshStandardMaterial({ color: '#89c854' })

const bushesValues = [
    {scale: 0.5, position: new THREE.Vector3(0.8, 0.2, houseSettings.width/2 + 0.1)},
    {scale: 0.25, position: new THREE.Vector3(1.4, 0.01, houseSettings.width/2 + 0.01)},
    {scale: 0.4, position: new THREE.Vector3(-0.9, 0.2, houseSettings.width/2 + 0.1)},
    {scale: 0.2, position: new THREE.Vector3(-1.3, 0.1, houseSettings.width/2 + 0.4)}
]

bushesValues.forEach(bushValue => {
    const bush = new THREE.Mesh(
        bushGeometry,
        bushMaterial
    )
    const {x, y, z} = bushValue.position;
    bush.position.set(x,y,z)
    bush.scale.set(bushValue.scale, bushValue.scale, bushValue.scale);
    bush.castShadow = true;
    house.add(bush)
});


// Graves

const gravesGroup = new THREE.Group();

const graveGeometry = new THREE.BoxGeometry(0.6, 0.8, 0.2);
const graveMaterial = new THREE.MeshStandardMaterial({
    color: "#a2a3a4"
})

let graves = []; // Array to keep track of the graves
const minDistanceX = 0.2;
const minDistanceZ = 0.2;

let count = 0;
while (graves.length < 50) {
    count++;
    const grave = new THREE.Mesh(
        graveGeometry,
        graveMaterial
    );
    const angle = Math.random() * Math.PI * 2;
    const minRadius = houseSettings.width / 2 + 1.2;
    const maxRadius = 9;
    const radius = minRadius + (Math.random() * (maxRadius - minRadius));

    grave.position.z = Math.sin(angle) * radius;
    grave.position.x = Math.cos(angle) * radius;

    // Check if the new grave is at least minDistanceX and minDistanceZ away from all other graves
    let isFarEnough = true;
    for (let existingGrave of graves) {
        const dx = Math.abs(grave.position.x - existingGrave.position.x);
        const dz = Math.abs(grave.position.z - existingGrave.position.z);
        
        if (dx < minDistanceX || dz < minDistanceZ) {
            isFarEnough = false;
            break;
        }
    }

    grave.position.y = 0.3;
    grave.rotation.z = (0.5 - Math.random()) * 0.5;
    grave.rotation.y = (0.5 - Math.random()) * 0.5;
    grave.castShadow = true;



    // Add the grave if it's far enough
    if (isFarEnough) {
        gravesGroup.add(grave);
        graves.push(grave);
    }
}

scene.add(gravesGroup);
// Floor
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(80, 80),
    new THREE.MeshStandardMaterial({ 
        map: grassColorTexture,
        aoMap: grassAmbientOcclusionTexture,
        normalMap: grassNormalTexture,
        roughnessMap: grassRoughnessTexture
    })
)
floor.rotation.x = - Math.PI * 0.5
floor.position.y = 0
scene.add(floor)

/**
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight('#b9d5ff', 0.12)
gui.add(ambientLight, 'intensity').min(0).max(1).step(0.001)
scene.add(ambientLight)

// Directional light
const moonLight = new THREE.DirectionalLight('#b9d5ff', 0.26)
moonLight.position.set(4, 5, - 2)
gui.add(moonLight, 'intensity').min(0).max(1).step(0.001)
gui.add(moonLight.position, 'x').min(- 5).max(5).step(0.001)
gui.add(moonLight.position, 'y').min(- 5).max(5).step(0.001)
gui.add(moonLight.position, 'z').min(- 5).max(5).step(0.001)
scene.add(moonLight)

// Door light
const doorLight = new THREE.PointLight("#ff7d46", 3, 7)
doorLight.position.set(0, houseSettings.height-0.2, houseSettings.width/2 + 0.7);
house.add(doorLight);


/**
 * Ghosts
 */
const ghost1 = new THREE.PointLight('#ff00ff', 6, 3)
scene.add(ghost1)

const ghost2 = new THREE.PointLight('#00ffff', 6, 3)
scene.add(ghost2)

const ghost3 = new THREE.PointLight('#ffff00', 6, 3)
scene.add(ghost3)


/**
 * Fog
 */
const fogColor = "#262837"
const fog = new THREE.Fog(fogColor, 1.5, 15)
scene.fog = fog;

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 4
camera.position.y = 2
camera.position.z = 5
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

// Set the maximum and minimum distance (zoom)
controls.minDistance = 4; // Minimum distance from the center (zoom out)
controls.maxDistance = 15; // Maximum distance from the center (zoom in)

// Set the maximum and minimum polar angle (vertical rotation)
controls.minPolarAngle = Math.PI / 5; // Minimum vertical rotation
controls.maxPolarAngle = Math.PI  * 0.48; // Maximum vertical rotation

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(fogColor)



/**
 * Shadows
 */

renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;


walls.castShadow = true;
roof.castShadow = true;

moonLight.castShadow = true;
moonLight.shadow.mapSize.width = 256
moonLight.shadow.mapSize.height = 256
moonLight.shadow.camera.far = 15

doorLight.castShadow = true;
doorLight.shadow.mapSize.width = 256
doorLight.shadow.mapSize.height = 256
doorLight.shadow.camera.far = 7

ghost1.castShadow = true;
ghost1.shadow.mapSize.width = 256
ghost1.shadow.mapSize.height = 256
ghost1.shadow.camera.far = 7

ghost2.castShadow = true;
ghost2.shadow.mapSize.width = 256
ghost2.shadow.mapSize.height = 256
ghost2.shadow.camera.far = 7

ghost3.castShadow = true;
ghost3.shadow.mapSize.width = 256
ghost3.shadow.mapSize.height = 256
ghost3.shadow.camera.far = 7

floor.receiveShadow = true;
walls.receiveShadow = true;


/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Ghosts
    const ghost1Angle = elapsedTime * 0.5
    ghost1.position.x = Math.cos(ghost1Angle) * 4
    ghost1.position.z = Math.sin(ghost1Angle) * 4
    ghost1.position.y = Math.sin(elapsedTime * 3)

    const ghost2Angle = - elapsedTime * 0.32
    ghost2.position.x = Math.cos(ghost2Angle) * 5
    ghost2.position.z = Math.sin(ghost2Angle) * 5
    ghost2.position.y = Math.sin(elapsedTime * 4) + Math.sin(elapsedTime * 2.5)

    const ghost3Angle = - elapsedTime * 0.18
    ghost3.position.x = Math.cos(ghost3Angle) * (7 + Math.sin(elapsedTime * 0.32))
    ghost3.position.z = Math.sin(ghost3Angle) * (7 + Math.sin(elapsedTime * 0.5))
    ghost3.position.y = Math.sin(elapsedTime * 4) + Math.sin(elapsedTime * 2.5)

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()